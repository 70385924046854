import React from 'react';
import ChooseCountryRegion from '../components/ChooseCountryRegion';
import ContextProvider from '../components/ContextProvider';

const CountryRegionSelectPage = ({ localeCode }: { localeCode?: string }) => {
  // Page layout
  return (
    <ContextProvider localeCode={localeCode}>
      <ChooseCountryRegion />
    </ContextProvider>
  );
};

export default CountryRegionSelectPage;
