import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { Box, Flex, Button } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import markdownRenderer from './MarkdownRenderer';
import { H4, H6, Paragraph, Caption } from './TextLibrary';
import { InternalLinkWithQuery } from '../utils/LinkWithQuery';

export interface GroupInterface {
  children: any;
}

// Container for a group of Account information
export const Group = ({ children, ...other }: GroupInterface) => (
  <Flex flexDirection="column" variant="card" mb={[3]} px={[0]} {...other}>
    {children}
  </Flex>
);

Group.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Row = ({
  children,
  bottom = false,
  top = false,
  isLink = false,
  ...others
}: {
  children: any;
  bottom?: boolean;
  top?: boolean;
  isLink?: boolean;
}) => {
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      mx={[3]}
      pt={top ? 0 : [2, 2, 3]}
      pb={bottom ? 0 : [2, 2, 3]}
      justifyContent="space-between"
      {...others}
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent="flex-start"
        alignItems={['left', 'left', 'center']}
        width={['100%']}
        flexWrap="wrap"
      >
        {children}
      </Flex>
      {isLink && (
        <Paragraph as="span" mb={[0]}>
          <FontAwesomeIcon icon={faAngleRight} />
        </Paragraph>
      )}
    </Flex>
  );
};

export const LinkWrapper = ({ children, ...others }: { children: any }) => {
  return (
    <Box
      sx={{
        ':hover': {
          backgroundColor: 'cloudFitBlue',
          color: 'light',
        },
        a: {
          ':hover': {
            textDecoration: 'none',
            color: 'light',
          },
        },
      }}
      {...others}
    >
      {children}
    </Box>
  );
};

export const Label = ({ children, ...other }: { children: any }) => {
  return (
    <Caption width={[1, 1, 1 / 6]} mb={[1, 1, 0]} {...other}>
      {children}
    </Caption>
  );
};

export const ContentContainer = ({ children }: { children: any }) => {
  return (
    <Flex width={[1, 1, 5 / 6]} flexDirection="column">
      <Paragraph as="div" mb={[0]} textAlign={['left']} lineHeight="tight">
        {children}
        <Box
          as="span"
          color="cloudFitBlue"
          sx={{ display: !children ? 'block' : 'none' }}
        >
          <FontAwesomeIcon icon={faSpinner} pulse />
        </Box>
      </Paragraph>
    </Flex>
  );
};

export interface TitleInterface {
  title: string;
  summary?: string;
  darkMode?: boolean;
}

// Title and summary component for a section
export const Title = ({
  title,
  summary,
  darkMode = false,
  ...other
}: TitleInterface) => {
  let hasSummary = false;
  let titleMB = [0];
  if (summary) {
    hasSummary = true;
    titleMB = [1];
  }

  return (
    <Box mx={[3]} pb={[2, 2, 3]}>
      <H4
        textAlign={['left']}
        mb={titleMB}
        {...other}
        color={darkMode ? 'light' : 'inherit'}
      >
        {title}
      </H4>
      {hasSummary ? (
        <Paragraph
          as="div"
          color={darkMode ? 'greyLow' : 'greyHigh'}
          mb={[0]}
          {...other}
        >
          <Paragraph as="span" mb={[0]} p={[0]} lineHeight="tight">
            <ReactMarkdown components={markdownRenderer}>
              {summary ? summary : ''}
            </ReactMarkdown>
          </Paragraph>
        </Paragraph>
      ) : (
        ''
      )}
    </Box>
  );
};
Title.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
};

export interface FieldInterface {
  label: string;
  description: string;
  isLink?: boolean;
}

// Field component
export const Field = ({
  label,
  description,
  isLink = false,
  ...other
}: FieldInterface) => {
  return (
    <Row isLink={isLink} {...other}>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="baseline"
        width="100%"
      >
        <Label>{label}</Label>
        <ContentContainer>
          {description && (
            <ReactMarkdown components={markdownRenderer}>
              {description}
            </ReactMarkdown>
          )}
        </ContentContainer>
      </Flex>
    </Row>
  );
};

Field.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
};

export const GroupCta = ({
  onClick,
  label,
  alt,
  notLast = false,
  disabled = false,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  alt: string;
  notLast?: boolean;
  disabled?: boolean;
}) => {
  let altLabel = alt;
  if (!altLabel) {
    altLabel = label;
  }

  return (
    <Paragraph as="span" mb={[0]} pt={[2]}>
      <Button
        variant="plain"
        color="cloudFitBlue"
        backgroundColor="light"
        sx={{
          textAlign: 'left',
          borderTop: 'solid',
          borderWidth: '1px',
          borderColor: 'cloudFitGrey',
          ':hover': {
            backgroundColor: 'greyLow',
            cursor: 'pointer',
          },
          ':disabled': {
            color: 'greyHigh',
            ':hover': {
              backgroundColor: 'light',
              cursor: 'auto',
            },
          },
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomLeftRadius: notLast ? '0px' : '8px',
          borderBottomRightRadius: notLast ? '0px' : '8px',
        }}
        onClick={onClick}
        alt={altLabel}
        px={[3]}
        py={[3]}
        width="100%"
        disabled={disabled}
      >
        {label ? (
          label
        ) : (
          <Box as="span" color="cloudFitBlue">
            <FontAwesomeIcon icon={faSpinner} pulse />
          </Box>
        )}
      </Button>
    </Paragraph>
  );
};

GroupCta.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export const ErrorMessage = ({
  children,
  visible = true,
  rightAlign = false,
  ...other
}: {
  children: any;
  visible?: boolean;
  rightAlign?: boolean;
}) => {
  return (
    <>
      <Box width={[1, 1, 1 / 6]} display={visible ? 'block' : 'none'} />
      <Caption
        width={[1, 1, 5 / 6]}
        mb={[0]}
        pl={[2]}
        pt={[2]}
        color="cloudFitRed"
        display={visible ? 'block' : 'none'}
        textAlign={rightAlign ? 'right' : 'left'}
        {...other}
      >
        {children}
      </Caption>
    </>
  );
};

export const BackLink = ({ children, to }: { children: any; to: string }) => {
  return (
    <Flex mt={[4, 3, 0]} mb={[2, 2, 3]}>
      <InternalLinkWithQuery to={to}>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="nowrap"
          mr="auto"
          color="greyHigh"
          sx={{ ':hover': { color: 'dark' } }}
        >
          <H6 as="span" mb={[0]}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </H6>
          <H6
            as="p"
            mb={[0]}
            pl={[1, 1, 2]}
            sx={{ textTransform: 'uppercase' }}
          >
            {children}
          </H6>
        </Flex>
      </InternalLinkWithQuery>
    </Flex>
  );
};

export default {
  Group,
  Title,
  Field,
  GroupCta,
  ErrorMessage,
  BackLink,
  Row,
  Label,
  ContentContainer,
};
