import React, { useContext, Fragment } from 'react';
import { navigate } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { Layout } from './Layout';
import markdownRenderer from './MarkdownRenderer';
import Section from './Section';
import TextLibrary from './TextLibrary';
import { SiteLocalesContext } from './ContextProvider';
import { Flex, Image } from 'rebass/styled-components';
import AccountLayout from '../components/LayoutComponents';
import { setGeo } from '../utils/Geo';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';

const ChooseCountryRegion = () => {
  const { t } = useTranslation('chooseCountryRegion');
  const { search } = useLocation();

  // Generate a list of languages that you can select from.
  // Order of countries is based on order as set in Contentful
  const siteLocales = useContext(SiteLocalesContext);
  let countries;
  if (siteLocales) {
    let cnt = 0;
    let borderBottom = 'solid';
    let borderBottomLeftRadius = '0px';
    let borderBottomRightRadius = '0px';
    let borderTop = 'none';
    let borderTopLeftRadius = '8px';
    let borderTopRightRadius = '8px';
    countries = siteLocales.supportedLocales.map((locale) => {
      cnt += 1;
      if (cnt > 1) {
        borderTopLeftRadius = '0px';
        borderTopRightRadius = '0px';
      }

      if (cnt === siteLocales.supportedLocales.length) {
        borderBottom = 'none';
        borderBottomLeftRadius = '8px';
        borderBottomRightRadius = '8px';
      }

      return (
        <Fragment key={locale.locale}>
          <Flex
            width="100%"
            flexDirection={['row', 'row', 'row']}
            alignItems="center"
            justifyContent="space-between"
            py={[2, 3, 3]}
            sx={{
              borderBottom,
              borderBottomLeftRadius,
              borderBottomRightRadius,
              borderTop,
              borderTopLeftRadius,
              borderTopRightRadius,
              borderWidth: '1px',
              borderColor: 'cloudFitGrey',
              ':hover': {
                backgroundColor: 'cloudFitBlue',
                color: 'light',
                cursor: 'pointer',
              },
              ':active': {
                backgroundColor: 'cloudFitBlueDark',
                color: 'light',
              },
            }}
            onClick={(event) => {
              event.preventDefault();
              setGeo(locale);
              navigate(`${locale.rootPath}${search}`);
            }}
          >
            <Flex
              width={[1]}
              flexDirection={['row']}
              alignItems="center"
              my={[1, 1, 2]}
              px={[3]}
            >
              <Image
                src={locale.flag && locale.flag.file.url}
                alt={locale.flag && locale.flag.description}
                height={['20px', '20px', '30px']}
                mr={[2]}
              />
              {locale.name}
            </Flex>
          </Flex>
        </Fragment>
      );
    });
  }

  return (
    <Layout
      title={t('metaTagTitle')}
      description={t('metaTagDescription')}
      colorTheme="light"
    >
      <Section.Container
        backgroundColor="light"
        id="chooseCountryPage.id"
        mt={[5, 6]}
      >
        <TextLibrary.H1>{t('title')}</TextLibrary.H1>
        <TextLibrary.H5 color="greyHigh">
          <ReactMarkdown components={markdownRenderer}>
            {t('description')}
          </ReactMarkdown>
        </TextLibrary.H5>

        <AccountLayout.Group p={[0]} mt={[3, 4, 4]}>
          <Flex
            width="100%"
            flexDirection={['column']}
            alignItems="center"
            my={[0]}
          >
            {countries}
          </Flex>
        </AccountLayout.Group>
      </Section.Container>
    </Layout>
  );
};

export default ChooseCountryRegion;
